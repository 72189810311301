.loader-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    max-width: 200px;
}

.loader {
    color: var(--accent-color);
}

.loader,
.loader:after {
    box-sizing: border-box;
}

.loader {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.loader:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6.4px solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: loader 1.2s linear infinite;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}