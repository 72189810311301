:root {
  --bg-color: #ffffff;
  --bg-second-color: #d0d0d0;

  --font-color: #111;
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  --font-size: 16px;

  --accent-color: #333;
  --accent-color-hover: #000;

  --border-color: #434343;

  --danger-color: #a61d24;
  --danger-color-hover: #6a1315;

  --success-color: #42a61d;
  --disabled-color: #8f8f8f;

  --info-color: #000064;
  --info-color-hover: #000048;

  --alert-bg: #ff8989;
}

* {
  margin: 0;
  right: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

body {
  background: var(--bg-color);
  font-family: var(--font-family);
  font-size: var(--font-size);
  color: var(--font-color);
  height: 100%;
}

::selection {
  background-color: var(--accent-color);
  color: white;
}

#root {
  height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: auto;
}

.section {
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
  justify-content: center;
  gap: 24px;
  max-width: 600px;
  height: calc(100vh - 160px);
}

.title {
  font-size: 32px;
  font-weight: 500;
  text-align: center;
}

.btn {
  background: var(--accent-color);
  font-size: 18px;
  color: white;
  border: 0;
  border-radius: 30px;
  padding: 18px 20px;
  cursor: pointer;
  width: 100%;
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

}

.btn-icon {
  width: 24px;
  height: 24px;
}

.btn:hover {
  background: var(--accent-color-hover);
}

.btn:disabled {
  background: var(--disabled-color);
}

.btn_link {
  background: transparent;
  color: var(--font-color);
}

.btn_link:hover {
  background: transparent;
  color: var(--font-color);
  text-decoration: underline;
}

.btn_info {
  background: var(--info-color);
}

.btn_info:hover {
  background: var(--info-color-hover);
}

.btn_danger {
  background: var(--danger-color);
}

.btn_danger:hover {
  background: var(--danger-color-hover);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.input {
  font-size: 16px;
  border-radius: 30px;
  padding: 18px 20px;
  width: 100%;
  text-decoration: none;
  text-align: center;
  display: block;
  border-color: var(--accent-color);
}

.text {
  text-align: center;
}

.btn_second {
  background: transparent;
  color: var(--font-color);
  border: 2px solid var(--accent-color);
}

.btn_second:hover {
  background: transparent;
}

.btns {
  display: flex;
  gap: 12px;
  width: 100%;
}

.fade-in {
  animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.approve-link {
  word-break: break-all;
}

ol {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 20px;
}
